import React,{useState,useEffect} from "react";
import Main from "../../components/template/Main";
import { Tabs,Tab } from "react-bootstrap";
import { parse,format } from "date-fns"; 
import Grupos from "./Grupos";
import './Avisos.css'
import { useHistory } from "react-router-dom";
import {get} from '../../store/config/register';
import { empresa_Id } from "../../store/config/dadosuser";
import useTable from "../../components/table/useTable";
import TableFooter from "../../components/table/TableFooter";

const headerProps = {
    icon: 'comments',
    title: 'Avisos'
  }

export default function Avisos() {
  const [avisos,setAvisos] = useState([]);
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(avisos, page, 10);
  const history = useHistory();
  
  async function buscaAvisos(){
    let avi = await get('/Aviso/v1/Empresa/'+empresa_Id)
    if(avi !== undefined ){
        setAvisos(avi);
    }
  }

  useEffect(() => {
    buscaAvisos();
  },[])

  function Visualizar(id) {
    history.push('/Avisos/Cadastro/'+id);
  }

  function renderTable() {
    return (
     
      <div className="aviso">
         {slice === undefined ? null : slice.map((aviso) => {
            return (
              <div className="mensagem" onClick={()=> Visualizar(aviso.id)}>
                <ul className="cont">
                  <li>Data do envio: &nbsp; {aviso.data === '' ? '' : format(parse(aviso.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy')}</li>
                  <li>Assunto: &emsp;&emsp;&emsp; {aviso.titulo}</li>
                </ul>
              </div>
            );

         })  
         }
         <div className="tabelaAviso">
          <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
         </div>
      </div>
      
    );
  }

    function renderForm(){

        return (
            <div className="btnNovo">
              <button className="btn btn-primary" onClick={()=> history.push('/Avisos/Cadastro')}>Novo</button>
              <hr />
                {renderTable()}
            </div>
        );
    }

    function CarregaAbas(){
        return (
          <Tabs defaultActiveKey={0}>
              <Tab title="Avisos" eventKey={0}>
                {renderForm()}
              </Tab>
              <Tab title="Grupos" eventKey={1}>
                  <Grupos />
              </Tab>      
          </Tabs>
        );
      }

    return (
        <Main {...headerProps}>
            {CarregaAbas()}    
        </Main>
    );
}