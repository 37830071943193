import React,{useState,useEffect} from "react";
import { parse,format } from "date-fns"; 
import { useHistory,useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { get, put } from '../store/config/register.js'
import { Calendar, momentLocalizer } from "react-big-calendar";
import 'moment/locale/pt-br';
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment/moment.js";
import { login_Id } from "../store/config/dadosuser.js";
import Main from "../components/template/Main";
import './ReservaEspaco.css'
import ModalAgenda from "./Modal/ModalAgenda.jsx";
import ModalAgendado from "./Modal/ModalAgendado.jsx";
import ModalRegras from "./Modal/ModalRegras.jsx";


const headerProps = {
    icon: "object-ungroup",
    title: "Reserva de Espaço",
  }

const initialState ={
    visitante: {
      id: 0,


    }
  }

  const messages = {
    allDay: 'Dia Inteiro',
    previous: '<',
    next: '>',
    today: 'Hoje',
    month: 'Mês',
    week: 'Semana',
    day: 'Dia',
    agenda: 'Agenda',
    date: 'Data',
    time: 'Hora',
    event: 'Evento'
}
  
export default function Reservas(){
    const [origemEspaco, setOrigemEspaco] = useState ({});
    const [usuario,setUsuario]=useState({});
    const [data,setData]=useState(new Date())
    const history = useHistory()
    const localizer = momentLocalizer(moment);
    const [myEventsList,setMyEventList]=useState([{ start: new Date('2023-08-08 08:00'), end: new Date('2023-08-08 14:00'), title: " (101 - A) special event" },{ start: new Date('2023-08-08 15:00'), end: new Date('2023-08-08 22:00'), title: " (101 - A) as special event" }]);
    const [selectedEvent, setSelectedEvent] = useState(undefined)
    const [modalState, setModalState] = useState(false)
    const [modalStateAgendado, setModalStateAgendado] = useState(false)
    const [modalStateRegras, setModalStateRegras] = useState(false)
    const [item,setItem]=useState({})
    const [agendado, setAgendado]=useState({})
    const [espaco,setEspaco] = useState({});
    const [horarioPadrao,setHorarioPadrao] = useState([]);
    const [regra,setRegra] =useState('');
    const {id}=useParams(); 
    const [horario, setHorario] = useState(false);
    const [horarios, setHorarios]=useState([]);


    useEffect(() => {
      buscaEspaco();
    },[buscaEspaco])

    useEffect(()=>{
      VerificaUsuario();
      buscaAgendamento(data);
      buscaRegras();
      buscaHorarioPadrao();
    },[])

    async function buscaHorarioPadrao() {
     const hors = await get('/EspacoPeriodoPadrao/v1/Espaco/'+ id);
     if(hors !== undefined){
      setHorarioPadrao(hors)
    }
    }


      function handleSelectedEvent (e){
        togglePostModalAgendado(e);
      }

      async function VerificaUsuario(){

        if(login_Id > 0){
          const  result = await  get(`/Login/v1/${login_Id}`);
          setUsuario(result)
       }
       
      }

      async function buscaEspaco() {
       const esp = await get('/Espaco/v1/'+ id);
        if(esp !== undefined){
          setEspaco(esp)
        }
      }

      async function buscaRegras() {
        const doc =await get('/EspacoDocumento/v1?IdEspaco='+id+'&Tipo=R');

        if(doc !== ''){
            setRegra(doc.texto)
        }
    }

      async function buscaAgendamento(data) {
        const even =await get(`/EspacoAgenda/v1/Espaco?espaco_id=${id}&mes=${data.getMonth()+1}&ano=${data.getFullYear()}`)
        if(even !== undefined) {
          let eventos =[];

          even.map((e) => {
            let ev ={ id:e.id ,espaco_id: id, unidade_id: e.unidade_Id ,start: new Date(e.inicio.replace('T',' ')), end: new Date(e.fim.replace('T',' ')), title: `${e.unidade} - ${e.descricao}`}
           eventos.push(ev);
          })

          setMyEventList(eventos)
        }
        

        //
      }

     async function handleSelect(e){
      let  dados = { data: e.start}
      let padrao = horarioPadrao.filter( f => f.dia_Semana === e.start.getDay()+1);
      dados.padrao =padrao;
        await Horarios(e.start.getDay()+1, dados)
        await  togglePostModal(dados)
      }

      function togglePostModal(item){
       
          item.espaco_id = id;
          item.usuario = usuario;
          item.login_id = login_Id;
         
          setItem({...item})   
        setModalState(!modalState) //trocando de true pra false
    }

    function togglePostModalAgendado(item) { 
      setModalStateAgendado(!modalStateAgendado);
      item.usuario_unidade = usuario.unidade_Id;
      setAgendado(item)
    }

    function togglePostModalRegras(item) { 
      setModalStateRegras(!modalStateRegras);
    }
      
    function clear() {
      history.push ('/Reservas')
    }

    function save () {
      
    }

    async function Horarios(diaSemana,dados){

       dados.Horario = false;
       const hors= await get(`/EspacoPeriodoHora/v1/Horarios?espaco_id=${id}&dia_semana=${diaSemana}`)
       if(hors.length > 0){
              setHorario(true);
              dados.Horario = true;

              hors.unshift({id: 0, hora_Inicio: '', hora_Fim: ''})
              dados.Horarios = hors;
              setHorarios(hors)
          }
      }

    function renderForm(){
      
        return (
            <div>

{/*
                <div className="titulo">
                  Detalhes da Reserva
                </div>
        */}
                <div className="tituloEspaco">
                  Local: {espaco.nome}
                </div>

                <div className="infoEspaco">
                  <div className="infoRegras">
                    <label onClick={(e) => {togglePostModalRegras()}}><i className={`fa fa-info-circle`}></i>Regras de uso</label>
                  </div>
                  <label><i className={`fa fa-users`}></i>Capacidade: {espaco.capacidade}  pessoas </label>
                  <label><i className={`fa fa-usd`}></i>Taxa de reserva: R$ {espaco.taxa}</label>
                  <label><i className={`fa fa-times`}></i>Cancelamento: {espaco.desistencia} horas</label>
                </div>

                {modalState && (
                <ModalAgenda 
                conteudo={item} 
                close={togglePostModal} />)}

                {modalStateAgendado && (
                  <ModalAgendado
                  conteudo={{agenda: agendado, espaco: espaco}} 
                  close={togglePostModalAgendado}
                  />
                )}
                {modalStateRegras && (
                  <ModalRegras
                  conteudo={{espaco: id, regra: regra ,espacoNome: espaco.nome}} 
                  close={togglePostModalRegras}
                  />
                )}
                <hr/>

                <div>
                    <Calendar
                        defaultView={'month'}
                        views={{ month: true, week: true, day: false, agenda: false, work_week:false,previous: false, next:false, today:false}}
                        defaultDate={data}
                        selectable={true}
                        localizer={localizer}
                        messages={messages}
                        events={myEventsList}
                        date={data}
                        value= {data}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 500 }}              
                        onSelectSlot={(e) => handleSelect(e)}
                        onSelectEvent={(e) => handleSelectedEvent(e)}
                        onNavigate={(date)=>{

                          buscaAgendamento(date);
                          setData(date);
                        }}
                    />
                </div>
                <hr/>

                <div>
                  <button className="btn btn-primary" onClick={e => save()}>
                    Salvar
                  </button>
                  <button className="btn btn-secondary ml-2" onClick={e => clear()}>
                    Cancelar
                  </button>
                </div>


            </div>

        );
    }
    return (
    <Main {...headerProps}>
        {renderForm()}
    </Main>
    );
}