import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import { isAuthenticated, isAdmin } from '../store/config/auth'

import Visitante from '../pages/Visitantes/Visitante';
import VisitanteUsuario from '../pages/Visitantes/VisitanteUsuario';
import Auth from '../pages/Auth/Auth';
import  Condominio  from '../pages/Condominio/Condominio';
import Autorizacao from '../pages/Autorizacao/Autorizacao';
import TabAutorizacao from '../pages/Autorizacao/TabAutorizacao';
import CadastrarUser from '../pages/Auth/CadastrarUser';
import ConfirmacaoEmail from '../pages/Autorizacao/ConfirmacaoEmail';
import TestCrypto from '../components/Teste/Teste';
import AgendaVisitante from '../pages/Visitantes/AgendaVisitante';
import QrCodeLeitura from '../pages/Qrcode/QrcodeLeitura';
import EsqueciSenha from '../pages/Auth/EsqueciSenha';
import NovaSenha from '../pages/Auth/NovaSenha';
import CadastroEspaco from '../pages/Espaco/CadastroEspaco';
import NovoEspaco from '../pages/Espaco/NovoEspaco';
import Documentos from '../pages/Documentos/Documentos';
import Menu from '../components/template/Menu'
import Reservas from '../pages/Reservas';
import ReservaEspaco from '../pages/ReservaEspaço'
import Editor from '../pages/Editor/Editor'
import Regras from '../pages/Regras/Regras';
import ReservaConvidado from '../pages/Reserva/ReservaConvidados';
import Avisos from '../pages/Avisos/Avisos';
import CadastroAviso from '../pages/Avisos/CadastroAviso';
import CadastroGrupo from '../pages/Avisos/CadastroGrupo';
import VisualizarAviso from '../pages/Avisos/VisualizarAviso';
import AvisosMorador from '../pages/Avisos/AvisosMorador';
import AutorizaReserva from '../pages/Reserva/AutorizaReserva';



const PrivateRoute = ({component: Component,...rest}) => (
    <Route {...rest} render={props =>
    isAuthenticated()  ? (
        isAdmin() === true ? 
        (<Redirect to={{ pathname: '/Menu', state:{from: props.location}}}/>) 
        :
        (<Component {...props}/>) 
        
    ) 
    :
    (
        <Redirect to={{ pathname: '/Auth', state:{from: props.location}}}/>
      
    )
   
    }/>
    
    );

    const AdminRoute = ({component: Component,...rest}) => (
        <Route {...rest} render={props =>
        isAuthenticated()  ? (
            isAdmin() === false ? 
            (<Redirect to={{ pathname: '/Menu', state:{from: props.location}}}/>) 
            :
            (<Component {...props}/>) 
            
        ) 
        :
        (
            <Redirect to={{ pathname: '/Auth', state:{from: props.location}}}/>
          
        )
       
        }/>
        
        );
    
    const OpenRoute = ({component: Component,...rest}) => (
        <Route {...rest} render={props =>
        isAuthenticated() === false ? (
            <Component {...props}/>
        ) 
        :
        (
//            <Redirect to={{ pathname: '/Visitantes', state:{from: props.location }}} />
            <Redirect to={{ pathname: '/Menu', state:{from: props.location }}} />
        )
        
        }/>
        );

        const FreeRoute = ({component: Component,...rest}) => (
            <Route {...rest} render={props =>
            isAuthenticated() === false ? (
                <Component {...props}/>
            ) 
            :
            (
//                <Redirect to={{ pathname: '/Visitantes', state:{from: props.location }}} />
                <Redirect to={{ pathname: '/Visitantes', state:{from: props.location }}} />
)
            
            }/>
            );

export default function Routes () {
    return (
        <Switch>
            <PrivateRoute exact path="/Visitante" component={ Visitante}/>
            <PrivateRoute exact path="/Visitante/:id" component={ Visitante}/>
            <PrivateRoute exact path="/Visitantes" component={ VisitanteUsuario}/>
            <PrivateRoute exact path="/Visitante/Agenda/:id" component={ AgendaVisitante}/>
            <PrivateRoute path="/Reservas" component={Reservas} />
            <PrivateRoute exact path="/ReservaEspaco" component={ReservaEspaco} />
            <PrivateRoute exact path="/ReservaEspaco/:id" component={ReservaEspaco} />
            <PrivateRoute exact path="/Regras" component={Regras} />
            <PrivateRoute exact path="/ReservaEspaco/Convidado/:idAgenda" component={ReservaConvidado} />
            <PrivateRoute exact path="/Piscina" component={() => {
                window.location.href = 'https://condominio.cisuporte.com.br/regimento/lavi_piscinas_saunas.html';
                return null;
                    }}/>
                    <PrivateRoute exact path="/Academia" component={() => {
                window.location.href = 'https://condominio.cisuporte.com.br/regimento/lavi_academia.html';
                return null;
                    }}/>
                    <PrivateRoute exact path="/Churrasqueira" component={() => {
                window.location.href = 'https://condominio.cisuporte.com.br/regimento/lavi_churrasqueira.html';
                return null;
                    }}/>
            <OpenRoute exact path="/Condominio" component={ Condominio}/>
            <OpenRoute  path="/Auth" component={Auth}/>
            <OpenRoute exact path="/Autorizacao" component={ Autorizacao}/>
            <OpenRoute exact path="/Autorizacao/:nome" component={ Autorizacao}/>
            <AdminRoute exact path="/Autorizados" component={ TabAutorizacao}/>
            <AdminRoute exact path="/Editor" component={ Editor}/>
            <OpenRoute exact path="/Cadastrar" component={CadastrarUser} />
            <OpenRoute exact path="/Confirmar/:dados" component={ConfirmacaoEmail} />
            <OpenRoute path="/Confirmar" component={ConfirmacaoEmail} />
            <Route exact path="/QRCode/:qrcode" component={QrCodeLeitura} />
            <OpenRoute exact path="/Teste" component={ TestCrypto}/>
            <OpenRoute exact path="/Usuario/EsqueciSenha" component={EsqueciSenha} />
            <AdminRoute exact path="/CadastroEspaco" component={CadastroEspaco}/>
            <AdminRoute exact path="/Reserva/Autoriza" component={AutorizaReserva}/>
            <AdminRoute exact path="/NovoEspaco" component={NovoEspaco}/>
            <AdminRoute exact path="/NovoEspaco/:id" component={NovoEspaco}/>
            {isAdmin() === true ? <AdminRoute exact path="/Menu" component={Menu}/> :
              <PrivateRoute exact path="/Menu" component={Menu}/>}
           
           {isAdmin() === true ? <AdminRoute exact path="/Avisos" component={Avisos}/> :
              <PrivateRoute exact path="/Avisos" component={AvisosMorador}/>}

            <OpenRoute exact path="/Usuario/NovaSenha/:id" component={NovaSenha} />
            <AdminRoute exact path="/Documentos" component={Documentos}/>
            <AdminRoute exact path="/Avisos/Cadastro" component={ CadastroAviso}/>
            <AdminRoute exact path="/Grupo/Cadastro" component={ CadastroGrupo}/>
            <AdminRoute exact path="/Grupo/Cadastro/:id" component={ CadastroGrupo}/>
            {isAdmin() === true ? <AdminRoute exact path="/Avisos/Cadastro/:id" component={VisualizarAviso}/> :
              <PrivateRoute exact path="/Avisos/Cadastro/:id" component={VisualizarAviso}/>}
            <Redirect from='*' to='/Auth'/>
        </Switch>
    );
} 
    