// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyApVOqcct6lAX72OT_Alo3yhjvZ7cGN6lM",
  authDomain: "condominio-visitante.firebaseapp.com",
  projectId: "condominio-visitante",
  storageBucket: "condominio-visitante.appspot.com",
  messagingSenderId: "799896316197",
  appId: "1:799896316197:web:8582b48c4865a7cfbdd2cc",
  measurementId: "G-608C1E5RYG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const messaging = getMessaging(app);

export const requestForToken = (setTokenFound) => {
  return getToken(messaging, {vapidKey: 'BHnQgWGrlrc0xoAyUoC9fHTZYR3UtNQ0A_qrhpcjvkhwmDrL6tXKCmGJNU4xiaVURJDdIQ6yjK1Fy5UP8xBOQCQ'}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      alert('current token for client: '+ currentToken);
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      alert('No registration token available. Request permission to generate one.');
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    alert(err);
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});