import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './main/App';
// import { messaging } from './firebase';

// messaging.requestPermission().then(() => {
//   return messaging.getToken();
// }).then((token) => {
//   console.log('Token:', token);
// }).catch((error) => {
//   console.error('Error al solicitar permisos:', error);
// });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);