import React, {useState, useEffect} from "react";
import Main from "../../components/template/Main";
import JoditEditor from "jodit-react";
import { useParams} from "react-router-dom";
import {get, put} from '../../store/config/register';
import addNotification from 'react-push-notification';
import { parse,format } from "date-fns"; 
import { login_Id } from "../../store/config/dadosuser";
import { isAdmin } from "../../store/config/auth";
import './Avisos.css'

const headerProps = {
    icon: 'comments',
    title: 'Visualizar Mensagem'
  }

  const copyStringToClipboard = function(str) {
    var el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };
  
  const facilityMergeFields = [
    "FacilityNumber",
    "FacilityName",
    "Address",
    "MapCategory",
    "Latitude",
    "Longitude",
    "ReceivingPlant",
    "TrunkLine",
    "SiteElevation"
  ];
  const inspectionMergeFields = [
    "InspectionCompleteDate",
    "InspectionEventType"
  ];
  const createOptionGroupElement = (mergeFields, optionGrouplabel) => {
    let optionGroupElement = document.createElement("optgroup");
    optionGroupElement.setAttribute("label", optionGrouplabel);
    for (let index = 0; index < mergeFields.length; index++) {
      let optionElement = document.createElement("option");
      optionElement.setAttribute("class", "merge-field-select-option");
      optionElement.setAttribute("value", mergeFields[index]);
      optionElement.text = mergeFields[index];
      optionGroupElement.appendChild(optionElement);
    }
    return optionGroupElement;
  }
  const buttons = [
    "undo",
    "redo",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "|",
    "align",
    "|",
    "ul",
    "ol",
    "outdent",
    "indent",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    {
      name: "insertMergeField",
      tooltip: "Insert Merge Field",
      iconURL: "images/merge.png",
      popup: (editor, current, self, close) => {
        function onSelected(e) {
          let mergeField = e.target.value;
          if (mergeField) {
            editor.selection.insertNode(
              editor.create.inside.fromHTML("{{" + mergeField + "}}")
            );
          }
        }
        let divElement = editor.create.div("merge-field-popup");
  
        let labelElement = document.createElement("label");
        labelElement.setAttribute("class", "merge-field-label");
        labelElement.text = 'Merge field: ';
        divElement.appendChild(labelElement);
  
        let selectElement = document.createElement("select");
        selectElement.setAttribute("class", "merge-field-select");
        selectElement.appendChild(createOptionGroupElement(facilityMergeFields, "Facility"));
        selectElement.appendChild(createOptionGroupElement(inspectionMergeFields, "Inspection"));
        selectElement.onchange = onSelected;
        divElement.appendChild(selectElement);
  
      //  console.log(divElement);
        return divElement;
      }
    },
    {
      name: "copyContent",
      tooltip: "Copy HTML to Clipboard",
      iconURL: "images/copy.png",
      exec: function(editor) {
        let html = editor.value;
        copyStringToClipboard(html);
      }
    }
  ];

  const editorConfig = {
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: "pt_br",
    toolbarButtonSize: "medium",
    toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    //defaultActionOnPaste: "insert_clear_html",
    buttons: buttons,
    uploader: {
      insertImageAsBase64URI: true
    },
    width: 800,
    height: 200
  };

  const initialState = {
    aviso: {
      id: 0,
      titulo: '',
      login_Nome: '',
      messagem: '',
      data: ''
    }
  }

export default function VisualizarAviso() {
    const [data,setData] = useState('');
    const [aviso, setAviso] = useState(initialState.aviso);
    const [progresso, setProgresso] = useState({});
    const {id} = useParams();

    useEffect(() => {
       buscaMensagem();
       
       
    },[aviso])

    useEffect(()=>{
      AtualizaLido();
      BuscaTotal();
    },[])

    async function BuscaTotal() {
    let tot =  await get('/AvisoPessoa/v1/Total/'+id);
        if(tot !== undefined){
          setProgresso(tot);      
        }
    }

    async function AtualizaLido() {
      let data = {aviso_Id: parseInt(id),login_Id: login_Id, lido: true}
      await put('/AvisoPessoa/v1/'+id,data)
    }

    function updateField(e){
        let avi = {...aviso};
        avi[e.target.name] =e.target.value;
        setAviso({...avi})
    }

    function notificacao() {
      let data ={ aviso_id: id, titulo: aviso.titulo, messagem: aviso.messagem, url: window.location.href}

      console.log(data)
        addNotification({
            title: data.titulo,
            subtitle: 'This is a subtitle',
            message: data.messagem,
            theme: 'darkblue',
            native: true // when using native, your OS will handle theming.
        });
    }

    async function buscaMensagem(){
        let avi = await get('/Aviso/v1/'+id);
        if(avi !== undefined){
          setData(avi.messagem)
          setAviso({...avi});
        }
    }

    function renderForm() {
        return (
            <div className="cadAvisoVisualiza">
              {isAdmin()  && (
                <div>
                  <label>{`Total que visualizaram: ${progresso.lido}/${progresso.total}`}</label>
                     <br />
                     <progress id="file" value={progresso.lido} max={progresso.total}> {progresso.lido} </progress>
                </div>                  
              )}
           
                <div className="field">
                  <div>Emitente: &ensp; {aviso.login_Nome}</div>
                  <div className="dataVisualizador">Data: &ensp;&ensp;&ensp;&ensp;&ensp;&nbsp; {aviso.data === '' ? '' : format(parse(aviso.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy')}</div>
                  <div>Assunto:<p className="avisoVisualizador">{aviso.titulo}</p></div>
                </div>

                <div className="col-10 fieldEditor"> 
                  <JoditEditor
                    value={data}
                    config={editorConfig}
                    onChange={value => {
                      setData(value); 
                      setAviso({...aviso,messagem: value});
                      ;}}

                  />
                </div>

                <div className="btnVisualizador">
                    <button className="btn btn-primary" onClick={()=> notificacao()}>Enviar Notificação</button>
                </div>
            </div>
        );
    }

    return(
        <Main {...headerProps}>
         {renderForm()}
        </Main>
    );
}