import React,{useState} from 'react';
import { useToasts } from 'react-toast-notifications';

function Teste() {
  const { addToast } = useToasts();
  const [notificationPermission, setNotificationPermission] = useState(Notification.permission);

  const handleRequestPermission = () => {
    Notification.requestPermission().then(permission => {
      setNotificationPermission(permission);
    });
  }

  const handlePushNotification = () => {
    if ('Notification' in window) {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          navigator.serviceWorker.ready.then(registration => {
            registration.showNotification('Push Notification Triggered', {
              body: 'This is a sample push notification.',
            });
          });
        }else {
          console.warn('Notification permission denied');
        }
      });
    }
  };
  const handleClick = () => {
    addToast('This is a push notification!', { appearance: 'success', autoDismiss: false });
  };

  return (
    <div>
      {notificationPermission === 'granted' ? (
          <div>
            <button onClick={handlePushNotification}>Show Notification</button>
          </div>
          ) : (
            <div>
              <p>Enable notifications to receive updates:</p>
              <button onClick={handleRequestPermission}>Enable Notifications</button>
            </div>
          )}
    </div>
    
  );
}

export default Teste;