import React, {useState} from "react";
import JoditEditor from "jodit-react";
import {FiX} from 'react-icons/fi';

export default function ModalPrintEditor({conteudo, close}) {

    const [item,setItem] =useState(conteudo);
    const [data,setData] = useState(conteudo.regra);

    const copyStringToClipboard = function(str) {
        var el = document.createElement("textarea");
        el.value = str;
        el.setAttribute("readonly", "");
        el.style = { position: "absolute", left: "-9999px" };
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
      };
      
      const facilityMergeFields = [
        "FacilityNumber",
        "FacilityName",
        "Address",
        "MapCategory",
        "Latitude",
        "Longitude",
        "ReceivingPlant",
        "TrunkLine",
        "SiteElevation"
      ];
      const inspectionMergeFields = [
        "InspectionCompleteDate",
        "InspectionEventType"
      ];
      const createOptionGroupElement = (mergeFields, optionGrouplabel) => {
        let optionGroupElement = document.createElement("optgroup");
        optionGroupElement.setAttribute("label", optionGrouplabel);
        for (let index = 0; index < mergeFields.length; index++) {
          let optionElement = document.createElement("option");
          optionElement.setAttribute("class", "merge-field-select-option");
          optionElement.setAttribute("value", mergeFields[index]);
          optionElement.text = mergeFields[index];
          optionGroupElement.appendChild(optionElement);
        }
        return optionGroupElement;
      }
      const buttons = [
        "print",
        {
          name: "insertMergeField",
          tooltip: "Insert Merge Field",
          iconURL: "images/merge.png",
          popup: (editor, current, self, close) => {
            function onSelected(e) {
              let mergeField = e.target.value;
              if (mergeField) {
                editor.selection.insertNode(
                  editor.create.inside.fromHTML("{{" + mergeField + "}}")
                );
              }
            }
            let divElement = editor.create.div("merge-field-popup");
      
            let labelElement = document.createElement("label");
            labelElement.setAttribute("class", "merge-field-label");
            labelElement.text = 'Merge field: ';
            divElement.appendChild(labelElement);
      
            let selectElement = document.createElement("select");
            selectElement.setAttribute("class", "merge-field-select");
            selectElement.appendChild(createOptionGroupElement(facilityMergeFields, "Facility"));
            selectElement.appendChild(createOptionGroupElement(inspectionMergeFields, "Inspection"));
            selectElement.onchange = onSelected;
            divElement.appendChild(selectElement);
      
          //  console.log(divElement);
            return divElement;
          }
        },
        {
          name: "copyContent",
          tooltip: "Copy HTML to Clipboard",
          iconURL: "images/copy.png",
          exec: function(editor) {
            let html = editor.value;
            copyStringToClipboard(html);
          }
        }
      ];
      
      const editorConfig = {
        readonly: false,
        toolbar: true,
        spellcheck: true,
        language: "pt_br",
        toolbarButtonSize: "medium",
        toolbarAdaptive: false,
        showCharsCounter: true,
        showWordsCounter: true,
        showXPathInStatusbar: false,
        askBeforePasteHTML: true,
        askBeforePasteFromWord: true,
        //defaultActionOnPaste: "insert_clear_html",
        buttons: buttons,
        uploader: {
          insertImageAsBase64URI: true
        },
        width: 800,
        height: 842
      };

    return (
        <div>
            <div className="modal3">
                <div className="container">
                    <button className="closeAgenda" onClick={ close }>
                        <FiX size={23} color="#FFF" />
                        Voltar
                    </button>
                        <JoditEditor
                            value={data}
                            config={editorConfig}
                            onChange={value => setData(value)}
                        />
                </div>
            </div>
        </div>
    );
}