import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'
import './App.css'
import React, {Component, useEffect, useState,useRef} from 'react'
import { BrowserRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
//import { registrar } from '../store/config/register'
import { isAuthenticated } from '../store/config/auth'
import { socketUrl } from '../global'
import {localStorageExpires} from '../store/config/storage'
import '@deepdub/react-ok-tooltip/dist/tooltip.css';
import Logo from '../components/template/Logo'
import Nav from '../components/template/Nav'
import Footer from '../components/template/Footer'
import Routes from './Routes'
import Menu from '../components/template/Menu';
import useWebSocket,{ ReadyState }  from 'react-use-websocket';
import addNotification, { Notifications } from 'react-push-notification';
import Teste from '../components/Teste/TesteNot';
import { ToastProvider } from 'react-toast-notifications';
import { requestForToken,onMessageListener } from '../firebase'
import { toast } from "react-toastify";


var initialState = {  isMenuVisible: true,
    user: {  }
};


//  async function notifyUser(notificationText='Obrigado por estabilishing notificações'){
//      if(!("Notification" in window)){
//          alert('Browser não suporta notificações')
     
//      }else if (Notification.permission === "granted"){
//          const notification = new Notification(notificationText);
//      }else if (Notification.permission === "denied"){
//         console.log(Notification.permission);
//          await Notification.requestPermission();
//         await Notification.requestPermission().then((permission) => {
//          console.log(permission);
//              if(permission === "granted"){
//                  const notification = new Notification(notificationText);
//              }
//         }).catch(e => {
//             console.log(e)
//          });
//      }
// }

// function notificacao() {
//    // let data ={ aviso_id: id, titulo: aviso.titulo, messagem: aviso.messagem, url: window.location.href}

//       addNotification({
//           title: "teste",
//           subtitle: 'This is a subtitle',
//           message: "uma mensagem",
//           theme: 'darkblue',
//           native: true // when using native, your OS will handle theming.
//       });
//   }


export default function App(){
    const connection = useRef(null)
const [state, setState]=useState(initialState);
const [userResponded, setUserResponded] = useState(false);
const [notification, setNotification] = useState({title: '', body: ''});
const [notificationPermission, setNotificationPermission] = useState(Notification.permission);
const [isTokenFound, setTokenFound] = useState(false);
const [show, setShow] = useState(false);
requestForToken(setTokenFound);

// inside the jsx being returned:
//  async function enableNotifsAndClose(){
//       await notifyUser();
//     setUserResponded(true);
//  }

onMessageListener().then(payload => {
    setShow(true);
    setNotification({title: payload.notification.title, body: payload.notification.body})
    toast.success(payload.notification.body);

    console.log(payload);
  }).catch(err => console.log('failed: ', err));


// function disableNotifsAndClose(){
//      setUserResponded(false);
//  }

// useEffect(()=>{
//      notificacao();
//  },[addNotification])

// const {
//     sendMessage,
//     sendJsonMessage,
//     lastMessage,
//     lastJsonMessage,
//     readyState,
//     getWebSocket,
//   } = useWebSocket(socketUrl, {
//     onOpen: () => console.log('opened'),
//     //Will attempt to reconnect on all close events, such as server shutting down
//     shouldReconnect: () => true,
//   });


  // Run when the connection state (readyState) changes
//   useEffect(() => {
//     console.log("Connection state changed")
//     if (readyState === ReadyState.OPEN) {
//       sendJsonMessage({
//         event: "subscribe",
//         data: {
//           channel: "general-chatroom",
//         },
//       })
//     }
//   }, [readyState])


useEffect(()=>{
    localStorageExpires();
  //  enableNotifsAndClose();
   if(isAuthenticated()){
    iniciarSocket();
   }
    
},[])

 

function iniciarSocket(){
    const socket = new WebSocket(socketUrl)

    // Connection opened
    socket.addEventListener("open", (event) => {
      socket.send("Connection established")
    })

    // Listen for messages
    socket.addEventListener("message", (event) => {
        if( JSON.parse(event.data) !== undefined ){
            alert("Message from server " +event.data)
        }

    })

    connection.current = socket

    return () => connection.close()
}

// useEffect(() => {
//    alert(`Got a new message: ${lastJsonMessage}`)
//   }, [lastJsonMessage])

   
    

        return (
             <BrowserRouter>
               <Notifications />
            <div >
           {notificationPermission === 'granted' ? (
            <div id='app' className={isAuthenticated() ? '' :'hide-menu'}>
            <ToastContainer autoClose={3000} />
           { isAuthenticated() ?<Logo /> :undefined}
           {/* {  isAuthenticated() ?<Nav /> :undefined } */}
            <Routes />
            <Footer />
            </div>
           )
           :(
            <div>
               <ToastProvider>
                <Teste />
                </ToastProvider>  
            </div>
           )}
          
       
           </div>
       </BrowserRouter>) 
}


