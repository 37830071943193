import React from 'react'
import './Footer.css'

export default function Footer() {
    return (
        <footer className="footer">
            <p>Desenvolvido por CI Sistemas de Informática Ltda.</p>
        </footer>
    );
}
