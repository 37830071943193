import React, { useState, useEffect } from 'react';
import Main from '../../components/template/Main';
import {get} from '../../store/config/register';
import { parse,format } from "date-fns"; 
import { empresa_Id } from '../../store/config/dadosuser';
import {FiX,  FiPrinter} from 'react-icons/fi';
import { useHistory } from "react-router-dom";
import ModalPrintEditor from '../Editor/ModalPrintEditor';

const headerProps = {
    icon: 'comments',
    title: 'Autorização de Reserva'
  }

export default function AutorizaReserva() {

 const [agendamentos,setAgendamentos]=useState([]);
 const history = useHistory();
 const [termo,setTermo] = useState({});
 const [showPostModal, setShowPostModal] = useState(false);

 function togglePostModal(item) {
  setShowPostModal(!showPostModal);
    let data = format(parse(item.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm');
  setTermo({regra: item.termo.replace('@Unidade',item.unidade).replace('@Bloco',item.bloco).replace("@Data",data.split(' ')[0]).replace('@Horario',data.split(' ')[1]).replace('@Responsavel',item.responsavel)})
  }

 const Print = (item) => {     
    //console.log('print');
    let data = format(parse(item.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm');
    let termo = item.termo.replace('@Unidade',item.unidade).replace('@Bloco',item.bloco).replace("@Data",data.split(' ')[0]).replace('@Horario',data.split(' ')[1]).replace('@Responsavel',item.responsavel);
    const printWindow = window.open("", "", "height=1000,width=1000");
    printWindow.document.write(termo);
    printWindow.print();
    //window.print();
   //document.body.innerHTML = originalContents; 
  }

     async function buscaAgendamento() {
     const agen = await get('/EspacoAgenda/v1/Empresa/'+empresa_Id);

        if(agen !== undefined){
            setAgendamentos(agen);
        }
     }

     useEffect(() =>{
        buscaAgendamento();
     },[])

    function renderForm() {
        return (
            <div className="form">
              <div className="row1">
                  <button className="btn btn-primary" onClick={e => history.push('/CadastroEspaco')}>
                    Novo Espaço
                  </button>
                  <button className="btnSairCad btn-primary" onClick={e => history.push('/Menu')}>
                    Sair
                  </button>
                </div>
                {showPostModal && (
                  <ModalPrintEditor
                  conteudo={termo} 
                  close={togglePostModal}
                  />
                  )
                }


                <hr/>
            {renderTable()}
        </div>
        );
        
    }

    function renderTable() {
        return (
          <div className='tabela'>
                      <table>
                        <thead>
                          <tr>
                            <th scope="col" className="Data">Data</th>
                            <th scope="col" className="Descricao">Descrição</th>
                            <th scope="col" className="Espaco">Espaço</th>
                            <th scope="col" className="Unidade">Unidade</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
  
                        <tbody>
                          {agendamentos === undefined ? null : agendamentos.map((agenda,index) => {
                            return (
                              <tr key={index}>
                                  <td data-label="Data">{format(parse(agenda.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm')}</td>
                                  <td data-label="Descricao">{agenda.descricao}</td>
                                  <td data-label="Espaco">{agenda.espaco}</td>
                                  <td data-label="Unidade">{agenda.unidade} - {agenda.bloco}</td>
                                  <td  data-label="">
                                  <button className="action" id="btn1" style={{backgroundColor: '#228B22' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Editar" onClick={() => togglePostModal(agenda)}>
                                    <FiPrinter color="#FFF" size={17} />
                                    </button>
                                    </td>
                                </tr>
                            );
                          })}
                                
  
  
                        </tbody>
                      </table>
  
                    </div>
        );
    }
    
    return (
        <Main {...headerProps}>
            {renderForm()}
        </Main>
    );
}